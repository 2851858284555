<template>
  <div>
    <div class="text-center">
      <v-btn
        v-bind="largeButton"
        @click="generateDocument()"
        :loading="generating"
        >Download</v-btn
      >
    </div>

    <download-doc
      title="Download Will"
      :token.sync="downloadToken"
      @download="downloadCompleted"
    ></download-doc>
    <v-overlay v-if="generating">
      <v-card>
        <v-card-text>
          <p>Generating document</p>
          <p>This can take up to 10 seconds.</p>
        </v-card-text>
        <v-progress-linear indeterminate></v-progress-linear>
      </v-card>
    </v-overlay>

    <dialog-small
      title="Your Will"
      :open.sync="openAccess"
    >
      <div class="pa-4">
        <div>
          Before you can download your Will you will need to complete all the
          steps and purchase it.
        </div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn
          text
          tile
          x-large
          block
          download
          @click="$router.push('/checkout')"
          >Go To Checkout<v-icon
            color="primary"
            size="24"
            right
            >mdi-chevron-right</v-icon
          ></v-btn
        >
      </template>
    </dialog-small>
    <dialog-small
      title="Complete Will"
      :open.sync="openIncomplete"
    >
      <div class="pa-4">
        <div>Complete all the sections of your Will to download</div>
      </div>
    </dialog-small>

    <dialog-small
      title="Required Details"
      :open.sync="openExecutorAddresses"
    >
      <div class="pa-4">
        <p>
          To complete your Will you will need to provide the addresses of all
          your Executors and backup Executors.
        </p>
        <p>
          You can enter the addresses by going to the Executor section and
          editing the details of your Executors.
        </p>
      </div>
    </dialog-small>
    <dialog-small
      title="Notify Executors"
      :open.sync="notify"
    >
      <notify-executors
        @close="notify = false"
        :executors="notifyExecutors"
      />
    </dialog-small>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

import { functions } from '../../../../../plugins/firebase'
import DownloadDoc from '../../../../components/site/DownloadDoc'
import DialogSmall from '../../../../components/wrapper/DialogSmall'
import mixins from '../mixins'
import NotifyExecutors from './NotifyExecutors'

export default {
  name: 'DownloadWillButton',
  mixins: [mixins],
  components: {
    NotifyExecutors,
    DownloadDoc,
    DialogSmall,
  },
  data: () => ({
    downloadToken: null,
    generating: false,
    notify: false,
    openAccess: false,
    openIncomplete: false,
    openExecutorAddresses: false,
    notifyExecutors: [],
  }),
  methods: {
    downloadCompleted() {
      const executers = this.$store.getters['account/will_executors']
      if (!executers.isComplete) return
      const primaryExecutors = executers.value.primary

      const checkList =
        primaryExecutors
          .map((x) => {
            const entity = this.$store.getters['account/entity'](x)
            if (!entity) return null
            return { notified: entity.notified, type: entity.type }
          })
          .filter((x) => x && x.type === 'person') || []

      const people = this.$store.getters['account/entities_people']
      this.notifyExecutors = cloneDeep(
        people
          .filter((x) => primaryExecutors.includes(x.id) && !x.notified)
          .map((x) => ({ id: x.id, name: x.name, email: x.email || null }))
      )

      if (checkList.length > 0 && !checkList.every((x) => x.notified)) {
        this.notify = true
      }
    },
    async generateDocument() {
      if (this.accessStatus === 'unactivated') {
        this.openAccess = true
        return
      }
      if (!this.$store.getters['account/will_steps_progress'].isComplete) {
        this.openIncomplete = true
        return
      }

      if (!this.$store.getters['account/will_executors'].isComplete) {
        this.openExecutorAddresses = true
        return
      }
      this.generating = true
      let resp = await functions.httpsCallable('documentsGenerateWillAu')({
        uid: this.$store.getters['account/auth_user'].uid,
      })
      if (resp.data?.token) {
        this.downloadToken = resp.data.token
      }
      this.generating = false
    },
  },
}
</script>

<style lang="scss"></style>
