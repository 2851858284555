<template>
  <div>
    <div class="inner">
      <div class="d-flex align-center">
        <h1 class="font-cooper">Your Will</h1>
        <v-spacer />
        <div>
          <v-btn
            v-if="
              will_steps_progress.isComplete && accessStatus === 'unactivated'
            "
            text
            color="black"
            class="text-capitalize"
            @click="$router.push('/checkout')"
            >Buy Now<v-icon
              right
              color="primary"
              >mdi-chevron-right</v-icon
            ></v-btn
          >
          <v-btn
            v-else-if="!will_steps_progress.isComplete"
            text
            color="black"
            class="text-capitalize"
            @click="continueToCurrent()"
            >Continue<v-icon
              right
              color="primary"
              >mdi-chevron-right</v-icon
            ></v-btn
          >
        </div>
      </div>
    </div>
    <div class="divider" />
    <accordion-main v-model="panel">
      <accordion-main-panel
        v-for="(item, index) in will_steps_progress.steps"
        :ref="`panel-${index}`"
        :key="index"
        :title="item.title"
        :subtitle="item.subtitle"
        :disabled="index > will_steps_progress.currentStep"
      >
        <template v-slot:header-prepend>
          <v-list-item-avatar class="will-step-icon radius-8">
            <div
              class="font-weight-bold mx-auto"
              :class="{ 'opacity-50': index > will_steps_progress.currentStep }"
            >
              {{ index + 1 }}
            </div>
          </v-list-item-avatar>
        </template>
        <template
          v-slot:header-append
          v-if="item.isStepComplete && index <= will_steps_progress.currentStep"
        >
          <v-list-item-avatar
            color="success"
            size="28"
            class="mr-3"
          >
            <v-icon
              color="white"
              size="18"
              >mdi-check-bold</v-icon
            >
          </v-list-item-avatar>
        </template>
        <complete-previous
          v-if="index > will_steps_progress.currentStep"
          @back="panel = will_steps_progress.currentStep"
        />
        <div v-else>
          <component
            v-if="item.key"
            :is="`will-${item.key}`"
            @next="panel++"
            :step="item"
            :access="user_access"
            class="mb-4"
            :accessStatus="accessStatus"
            @accessDenied="accessWarning"
          />

          <template v-if="item.isStepComplete">
            <div v-if="!item.isLastStep">
              <v-card
                outlined
                width="220"
              >
                <v-list-item
                  :disabled="processing"
                  link
                  @click="
                    item.isStepComplete || item.skip
                      ? nextPanel(item.skip || null)
                      : (showError[item.key] = true)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>Next Step</v-list-item-subtitle>
                    <h3>{{ nextStepLabels[item.key] }}</h3>
                  </v-list-item-content>
                  <v-list-item-avatar
                    class="bg-grey-20"
                    size="32"
                  >
                    <v-icon
                      color="primary"
                      size="20"
                      >mdi-arrow-right</v-icon
                    >
                  </v-list-item-avatar>
                </v-list-item>
                <v-overlay
                  v-model="processing"
                  absolute
                  :opacity="0.25"
                >
                  <v-progress-circular
                    indeterminate
                    size="32"
                    color="primary"
                  />
                </v-overlay>
              </v-card>
              <v-input
                :error-messages="
                  showError[item.key] && !item.isStepComplete
                    ? 'Complete this section before continuing'
                    : null
                "
              />
            </div>
            <div v-else-if="accessStatus !== 'active'">
              <v-card
                outlined
                width="220"
              >
                <v-list-item
                  :disabled="processing"
                  link
                  @click="$router.push('/checkout')"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>Next Step</v-list-item-subtitle>
                    <h3>Get your Will</h3>
                  </v-list-item-content>
                  <v-list-item-avatar
                    class="bg-grey-20"
                    size="32"
                  >
                    <v-icon
                      color="primary"
                      size="20"
                      >mdi-arrow-right</v-icon
                    >
                  </v-list-item-avatar>
                </v-list-item>
                <v-overlay
                  v-model="processing"
                  absolute
                  :opacity="0.25"
                >
                  <v-progress-circular
                    indeterminate
                    size="32"
                    color="primary"
                  />
                </v-overlay>
              </v-card>
              <v-input
                :error-messages="
                  showError[item.key] && !item.isStepComplete
                    ? 'Complete this section before continuing'
                    : null
                "
              />
            </div>
          </template>
        </div>
      </accordion-main-panel>
      <v-overlay
        :value="accessStatus === 'expired'"
        absolute
        class="text-center"
        color="black"
        opacity="0.6"
      >
        <v-icon
          size="48"
          class="mb-4"
          color="white"
          >mdi-lock-outline</v-icon
        >
        <p class="text-white font-weight-bold">
          Renew your account to continue to make changes to your Will.
        </p>
        <v-btn
          v-bind="smallButton"
          @click="$router.push('/checkout')"
          >Renew</v-btn
        >
      </v-overlay>
    </accordion-main>
    <div class="divider" />
    <div class="pa-4">
      <download-will-button :access-status="accessStatus" />
    </div>
    <dialog-small
      title="Your Will"
      :open.sync="openAccess"
    >
      <div class="pa-4">
        <p>To make changes to your Will you will need to renew your account.</p>
        <div>Renew now to continue updating your Will, Vault and Wishes.</div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn
          text
          tile
          x-large
          block
          download
          @click="$router.push('/checkout')"
          >Renew Will</v-btn
        >
      </template>
    </dialog-small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AccordionMain from '../../components/wrapper/AccordionMain'
import AccordionMainPanel from '../../components/wrapper/AccordionMainPanel'
import DialogSmall from '../../components/wrapper/DialogSmall'
import CompletePrevious from './blocks/CompletePrevious'
import WillAbout from './sections/About'
import DownloadWillButton from './sections/download/DownloadWillButton'
import WillEstate from './sections/Estate'
import WillExecutors from './sections/Executors'
import WillGifts from './sections/Gifts'
import WillGuardians from './sections/Guardians'
import WillPets from './sections/Pets'
import WillWishes from './sections/Wishes'

export default {
  name: 'PageWillSummary',
  components: {
    WillAbout,
    WillWishes,
    WillPets,
    WillGifts,
    WillExecutors,
    WillEstate,
    WillGuardians,
    DownloadWillButton,
    CompletePrevious,
    AccordionMain,
    AccordionMainPanel,
    DialogSmall,
  },
  data: () => ({
    processing: false,
    openAccess: false,
    panel: null,
    showError: {
      about: false,
      guardians: false,
      pets: false,
      executors: false,
      estate: false,
      gifts: false,
      wishes: false,
    },
  }),
  created() {
    this.panel = !this.$store.getters['account/user_name'].isComplete
      ? 0
      : this.panel
  },
  methods: {
    async nextPanel(key = null, checkout = false) {
      if (key) {
        this.processing = true
        await this.$store.dispatch('account/update_will_progress', [key])
        this.processing = false
      }

      if (checkout) {
        await this.$router.push('/checkout')
      } else {
        this.panel++
      }
    },
    accessWarning() {
      this.openAccess = true
    },
    continueToCurrent() {
      this.panel = null
      setTimeout(() => {
        this.panel = this.will_steps_progress.currentStep
      }, 100)
    },
  },
  computed: {
    ...mapGetters('account', ['user_access', 'will_steps_progress']),
    nextStepLabels() {
      let labels = {}
      this.will_steps_progress.steps.forEach((o, i) => {
        labels[o.key] = this.will_steps_progress.steps[i + 1]?.title || null
      })

      return labels
    },
    accessStatus() {
      if (this.user_access.hasExpired) {
        return 'expired'
      }
      if (this.user_access.hasPurchasedWill) {
        return 'active'
      }

      return 'unactivated'
    },
  },
}
</script>

<style lang="scss">
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header:hover
  .will-step-icon {
  background-color: #ffffff;
}
.will-step-icon {
  background-color: var(--primary-10);
}
</style>
