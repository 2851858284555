<template>
  <div
    v-ripple
    class="child-charity-box"
    @click="$emit('select', charity)"
  >
    <v-img
      v-if="charity.logo && charity.logo.default"
      max-width="110"
      max-height="70"
      contain
      lazy-src="@/assets/images/loading-rolling.svg"
      :src="charity.logo.default"
      :title="charity.name"
      :aspect-ratio="2"
    />
    <div class="name">{{ charity.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'ChildCharityBox',
  props: {
    charity: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.child-charity-box {
  display: flex;
  cursor: pointer;
  gap: 16px;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  border: 2px solid #d6dce7;
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  .name {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
}
</style>
