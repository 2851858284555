<template>
  <div>
    <div class="pa-4">
      <p>
        Enter the email addresses of your Executors below and we can notify them
        that you have created a Will and that they have been nominated as an
        Executor.
      </p>

      <div
        v-if="localExecutors.length === 0"
        class="results-empty"
        key="empty"
      >
        No Executors added
      </div>
      <div
        v-else
        key="list"
      >
        <div
          v-for="item in localExecutors"
          :key="item.id"
        >
          <div class="font-weight-bold mb-2">{{ item.name }}</div>
          <v-text-field
            outlined
            v-model="item.email"
            type="email"
            label="Email Address"
            @input="showErrorsSwitch(true, item.id)"
            @blur="showErrorsSwitch(true, item.id)"
            :error-messages="
              showErrors[item.id] && errorMessages[item.id]
                ? errorMessages[item.id]
                : null
            "
          ></v-text-field>
        </div>
      </div>
    </div>

    <div class="divider" />
    <v-btn
      :loading="processing"
      text
      tile
      x-large
      block
      @click="save"
      class="text-capitalize"
      :disabled="!isValid"
      v-bind="buttonType"
    >
      <v-icon
        left
        color="primary"
        size="24"
        >mdi-email-outline</v-icon
      >Send Notification</v-btn
    >
  </div>
</template>
<script>
import { send_mail } from '@/plugins/firebase'

export default {
  name: 'NotifyExecutors',
  props: {
    notify: {
      default: false,
    },
    executors: {
      default() {
        return []
      },
    },
  },
  data() {
    return {
      localExecutors: [],
      showErrors: {},
      processing: false,
    }
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },

    isValid() {
      return (
        !this.localFormatted.every(
          (val) => !val.email || val.email.length === 0
        ) && Object.values(this.errorMessages).every((val) => val === null)
      )
    },
    errorMessages() {
      const msgs = {}
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      this.localFormatted.forEach((e) => {
        if (e.email && e.email.length > 0 && !pattern.test(e.email)) {
          this.$set(msgs, e.id, 'Not a valid email address')
        }
      })

      return msgs
    },
    localFormatted() {
      return this.localExecutors.map((x) => ({ ...x, email: x.email?.trim() }))
    },
    emails() {
      return this.localFormatted
        .filter((x) => x.email)
        .reduce(
          (obj, x) => ({
            ...obj,
            [x.email]: {
              executor: x.name.split(' ')[0],
              willmaker: this.$store.getters['account/user_name'].displayFull,
            },
          }),
          {}
        )
    },
  },
  watch: {
    executors: {
      handler(v) {
        this.localExecutors = v
      },
      immediate: true,
    },
  },
  methods: {
    showErrorsSwitch(show = true, id) {
      if (id) {
        this.$set(this.showErrors, id, { email: show })
        return
      }

      Object.keys(this.showErrors).forEach((e) => {
        this.$set(this.showErrors, e, { email: show })
      })
    },

    async save() {
      this.showErrorsSwitch()
      this.processing = true
      if (this.isValid) {
        await send_mail(
          this.$store.getters['account/auth_user'].uid,
          'notifyExecutor',
          this.emails
        ).then(() => {
          this.localFormatted
            .filter((x) => x.email)
            .forEach(async (person) => {
              await this.$store.dispatch('account/save_entity_person', {
                id: person.id,
                person: { email: person.email, notified: true },
              })
            })
        })

        setTimeout(() => {
          this.processing = false
          this.$emit('close')
        }, 500)
      }
    },
  },
}
</script>
