<template>
  <div
    class="charity-box"
    v-ripple
  >
    <div
      class="charity-logo text-center pa-1"
      @click="$emit('select', charity)"
    >
      <v-img
        height="68px"
        contain
        lazy-src="@/assets/images/loading-rolling.svg"
        :src="charity.logo.default"
        :title="charity.name"
        :aspect-ratio="2"
        v-if="charity.logo && charity.logo.default"
      />
      <div
        class="text-primary d-flex w-[100%] h-[100px] items-center justify-center"
        v-else
      >
        <h1 class="h-[68px]">
          {{
            charity.name
              .split(' ')
              .map((x) => x.substring(0, 1))
              .join('')
              .toUpperCase()
          }}
        </h1>
      </div>
    </div>
    <div
      class="item-label d-flex justify-space-between flex-nowrap"
      :title="charity.name"
    >
      <div
        @click="$emit('select', charity)"
        class="flex-grow-1"
      >
        {{ charity.name }}
      </div>
      <v-btn
        icon
        x-small
        v-if="charity.settings.showSummary && charity.summary"
        @click="$emit('info', charity)"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CharityBox',
  props: {
    charity: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.charity-box {
  border: 2px solid #d6dce7;
  border-radius: 6px;
  background-color: rgba(255, 255, 255);
  cursor: pointer;
  .charity-logo {
    position: relative;
  }
  &:hover .charity-logo:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  img,
  h1 {
    padding: 0.1rem;
    margin: 6px;
    height: 56px;
    max-width: calc(100% - (0.2rem * 2));
  }

  .item-label {
    width: 100%;
    font-weight: 600;
    color: #000;
    font-size: 0.8rem;
    background-color: #d6dce7;
    padding: 0.5rem;

    & > :first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
