<template>
  <drawer
    :open.sync="openValue"
    title="Select child charity"
  >
    <div
      v-if="parent?.summary"
      v-html="parent.summary"
      class="pa-4"
    ></div>
    <div
      v-for="(charity, index) in charities"
      :key="index"
      class="my-4 px-4"
    >
      <child-charity-box
        :charity="charity"
        @select="$emit('select', charity), (openValue = false)"
      />
    </div>
  </drawer>
</template>

<script>
import Drawer from '../wrapper/Drawer'
import ChildCharityBox from './ChildCharityBox'

export default {
  name: 'SelectChildCharity',
  components: { ChildCharityBox, Drawer },
  props: ['open', 'parent'],
  computed: {
    openValue: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('update:open', val)
      },
    },
    charities() {
      return this.parent
        ? this.parent.children.map((id) =>
            this.$store.getters['app/all_charities'].find(
              (charity) => charity.id === id
            )
          )
        : []
    },
  },
}
</script>
