<template>
  <v-dialog
    :max-width="500"
    persistent
    v-model="showConfirmChangeState"
  >
    <v-card>
      <v-list-item
        dense
        class="bg-sand"
      >
        <v-list-item-content>
          <h3 class="title">Warning</h3>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="py-4">
        <v-list-item-content>
          <div class="font-weight-bold mb-4">
            Changing your state will delete the Power of Attorney data you have
            saved for {{ currentStateLabel }}. Are you sure you want to do this?
            You can always complete the forms again if you move back to
            {{ currentStateLabel }}.
          </div>
          <slot />
        </v-list-item-content>
      </v-list-item>
      <div class="divider" />
      <v-btn
        text
        tile
        x-large
        block
        download
        @click="$emit('confirmChangeState')"
        color="red"
        >Confirm change state</v-btn
      >
      <div class="divider" />
      <v-btn
        text
        tile
        block
        class="bg-grey-10"
        @click="$emit('close')"
        >Cancel</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmChangeState',
  props: {
    showConfirmChangeState: {
      default: false,
    },
    currentStateLabel: {
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-family: CooperBT;
}
</style>
