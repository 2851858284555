<template>
  <v-card
    outlined
    class="btn-add-large"
    :class="{ disabled: disabled }"
  >
    <v-list-item
      @click="disabled ? $emit('clickDisabled') : $emit('click')"
      class="item"
    >
      <v-list-item-avatar
        color="primary"
        size="28"
      >
        <v-icon
          color="white"
          size="24"
          >{{ icon }}</v-icon
        >
      </v-list-item-avatar>
      <v-list-item-content class="py-2">
        <div
          :class="labelSize"
          class="font-weight-medium"
        >
          {{ label }}
        </div>
        <div
          v-if="subtitle"
          class="subtitle"
        >
          {{ subtitle }}
        </div>
        <div v-if="text">{{ text }}</div>
      </v-list-item-content>
      <v-list-item-action
        v-if="img"
        tile
        class="my-0"
      >
        <img
          :src="img"
          height="48"
          width="auto"
        />
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'BtnAddLarge',
  props: {
    label: {
      default: 'Add',
    },
    disabled: {
      default: false,
    },
    tooltip: {
      default: null,
    },
    subtitle: {
      default: null,
    },
    text: {
      default: null,
    },
    img: {
      default: null,
    },
    error: {
      default: null,
    },
    labelSize: {
      default: 'text-lg',
    },
    icon: {
      default: 'mdi-plus',
    },
  },
  data: () => ({}),
}
</script>

<style lang="scss">
.btn-add-large {
  &.disabled {
    .item {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .item {
    min-height: 64px;
  }
  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0, 0.6);
    margin-top: 4px;
  }
  border-width: 2px !important;
}
</style>
