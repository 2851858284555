<template>
  <v-container>
    <div
      v-if="charities.length === 0"
      class="text-center no-result"
    >
      There are no charities matching your search.
    </div>
    <v-row
      v-else
      justify="center"
      align-content="center"
    >
      <div
        class="col-6"
        v-for="(charity, index) in charities"
        :key="index"
      >
        <charity-box
          :charity="charity"
          @select="selectCharity"
          @info="
            sumCharity = $event
            infoDialog = true
          "
        />
      </div>
    </v-row>
    <v-dialog
      persistent
      max-width="520px"
      v-model="sumCharity"
      v-if="sumCharity"
      content-class="info-dialog"
    >
      <v-card class="dialog-form">
        <v-card-title class="w-title d-flex justify-space-between flex-nowrap">
          <div>
            {{ sumCharity.name }}
          </div>
          <v-btn
            icon
            @click="sumCharity = null"
          >
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <perfect-scrollbar
          class="px-6 py-3"
          v-html="sumCharity.summary"
        >
        </perfect-scrollbar>
        <v-card-actions class="actions d-flex justify-end align-end px-6 pb-4">
          <v-btn
            v-bind="outlineButton"
            @click="sumCharity = null"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <select-child-charity
      :open.sync="selectChildCharityDialog"
      :parent="charityWithChildren"
      @select="selectChildCharity"
    />
  </v-container>
</template>

<script>
import CharityBox from './CharityBox'
import SelectChildCharity from './SelectChildCharity'

export default {
  name: 'CharitySearchResults',
  components: { CharityBox, SelectChildCharity },
  props: ['charities'],
  data: () => ({
    sumCharity: null,
    selectChildCharityDialog: false,
    charityWithChildren: null,
  }),
  methods: {
    async selectCharity(charity) {
      if (charity.children && charity.children.length) {
        this.charityWithChildren = charity
        this.selectChildCharityDialog = true
        return
      }
      this.$emit('select', charity)
    },

    async selectChildCharity(charity) {
      this.$emit('select', charity)
    },
  },
}
</script>

<style scoped lang="scss">
.charity-partner-item {
  margin: 0 auto 1rem;
  width: 50%;
  min-height: 200px;

  &:hover {
    opacity: 0.8;
  }
}

.w-title > :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: CooperBT;
}

.charity-wrapper {
  border: 2px solid #d6dce7;
  border-radius: 6px;
  margin: 0;
  justify-content: center !important;
}

.charity-box {
  border: 2px solid #d6dce7;
  border-radius: 6px;
  background-color: rgba(255, 255, 255);
  cursor: pointer;
  .charity-logo {
    position: relative;
  }
  &:hover .charity-logo:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  img,
  h1 {
    padding: 0.1rem;
    margin: 6px;
    height: 56px;
    max-width: calc(100% - (0.2rem * 2));
    font-family: CooperBT;
  }

  .item-label {
    width: 100%;
    font-weight: 600;
    color: #000;
    font-size: 0.8rem;
    background-color: #d6dce7;
    padding: 0.5rem;

    & > :first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
