export default {
  props: {
    step: {
      default: null,
    },
    access: {
      default: null,
    },
    accessStatus: {
      default: null,
    },
  },
}
