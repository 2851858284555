<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4">What is your current relationship status?</h2>
        <faqs
          class="mb-4"
          :faqs="['updateRelationship']"
        />
        <item-select
          v-for="(item, index) in statusOptions"
          :key="item.key"
          :value="item.key === localFormatted.relationshipStatus"
          @select="selectOption(item.key)"
          :title="item.label"
          :text="item.text"
          :class="{ 'mb-2': index + 1 < statusOptions.length }"
        />
        <v-input
          :error-messages="
            showErrors.status && errorMessages.status
              ? errorMessages.status
              : null
          "
        />
      </div>
      <v-expand-transition>
        <div
          class="mb-8"
          v-show="requiresPartner"
        >
          <h2 class="mb-4">Who is your partner?</h2>
          <transition
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-if="localFormatted.partnerId"
              :key="localFormatted.partnerId"
              :id="localFormatted.partnerId"
              class="mb-2"
            />
          </transition>

          <btn-add-large
            @click="openDrawer = true"
            :label="buttonLabel"
            :icon="buttonIcon"
          />
          <v-input
            :error-messages="
              showErrors.partner && errorMessages.partner
                ? errorMessages.partner
                : null
            "
          />
          <div
            v-if="requiresPartner && !hasPurchasedWill"
            class="mb-4"
          >
            <notification-box
              type="info"
              :flat="true"
            >
              <div class="text-label">Get 50% off your partner's Will</div>
              <p>
                When you are ready to purchase your Will you can also purchase
                your partner’s Will for half price.
              </p>
              <div>
                After paying for both Wills, you will receive a link so that
                your partner can open a Willed account and create their Will at
                their convenience.
              </div>
            </notification-box>
          </div>
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawer"
      title="Add Partner"
    >
      <drawer-person-select
        v-if="openDrawer"
        @select="addPartner"
        :exclude="excludePeople"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { mapGetters } from 'vuex'

import BtnAddLarge from '../../../components/ui/BtnAddLarge'
import Faqs from '../../../components/ui/Faqs'
import ItemSelect from '../../../components/ui/ItemSelect'
import NotificationBox from '../../../components/ui/NotificationBox'
import Drawer from '../../../components/wrapper/Drawer'
import DrawerPersonSelect from '../../../components/wrapper/DrawerPersonSelect'
import ItemPerson from '../../../components/wrapper/ItemPerson'

export default {
  name: 'WillFormPartner',
  components: {
    NotificationBox,
    Faqs,
    ItemSelect,
    BtnAddLarge,
    Drawer,
    ItemPerson,
    DrawerPersonSelect,
  },
  props: {
    closeLabel: {
      default: 'close',
    },
    submitLabel: {
      default: 'save',
    },
    id: {
      default: null,
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      status: false,
      partner: false,
    },
    processing: false,
    openDrawer: false,
    hasPurchasedWill: false,
  }),
  mounted() {
    this.local = cloneDeep(this.user_partner.value)
    this.hasPurchasedWill =
      this.$store.getters['account/user_access'].hasPurchasedWill
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch('account/save_user_data', this.localFormatted)
      await this.$store.dispatch('account/update_will_progress', ['partner'])
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addPartner(id) {
      this.local.partnerId = id
      this.openDrawer = false
    },
    selectOption(option) {
      this.local.relationshipStatus = option
      if (option === 'single') {
        this.showErrorsSwitch(false, 'partner')
      }
    },
  },
  computed: {
    ...mapGetters('account', ['user_partner', 'entities_people']),
    excludePeople() {
      return [
        this.localFormatted.partnerId,
        ...this.entities_people
          .filter((x) => x.guardians && x.guardians.length)
          .map((x) => x.id),
      ]
    },
    statusOptions() {
      return this.$store.getters['account/list_group']('relationshipStatus')
    },
    requiresPartner() {
      return this.statusOptions.some((o) => {
        return o.key === this.localFormatted.relationshipStatus && o.partner
      })
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (this.requiresPartner && !this.localFormatted.partnerId) {
        msgs.partner = 'Required'
      }

      if (!this.localFormatted.relationshipStatus) {
        msgs.status = 'Required'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.user_partner.value)
    },
    localFormatted() {
      let relationshipStatus = this.local?.relationshipStatus || null
      let partnerId = this.local?.partnerId || null
      let statusOption = relationshipStatus
        ? this.statusOptions.find((o) => o.key === relationshipStatus) || null
        : null

      return {
        relationshipStatus: statusOption ? relationshipStatus : null,
        partnerId: statusOption?.partner ? partnerId : null,
      }
    },
    buttonLabel() {
      return this.localFormatted.partnerId ? 'Change Person' : 'Add Person'
    },
    buttonIcon() {
      return this.localFormatted.partnerId ? 'mdi-sync' : 'mdi-plus'
    },
  },
}
</script>

<style lang="scss"></style>
