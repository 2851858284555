<template>
  <div class="charity-wrapper">
    <v-list-item class="header">
      <v-list-item-avatar
        color="primary"
        size="28"
      >
        <v-icon
          color="white"
          size="24"
          >mdi-plus</v-icon
        >
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="text-lg font-weight-medium">Add Charity</div>
      </v-list-item-content>
    </v-list-item>
    <div class="row px-4">
      <template v-if="charities">
        <div
          class="col-6"
          v-for="(charity, index) in charities"
          :key="index"
        >
          <charity-box
            :charity="charity"
            @select="selectCharity"
            @info="
              sumCharity = $event
              infoDialog = true
            "
          />
        </div>
      </template>
    </div>
    <div
      class="row"
      v-if="$slots.footer"
    >
      <div class="col-12 pb-6">
        <slot name="footer"></slot>
      </div>
    </div>

    <dialog-small
      :title="sumCharity.name"
      :open.sync="infoDialog"
      v-if="sumCharity"
    >
      <div class="pa-4">
        <div v-html="sumCharity.summary"></div>
      </div>
    </dialog-small>

    <select-child-charity
      :open.sync="selectChildCharityDialog"
      :parent="charityWithChildren"
      @select="selectChildCharity"
    />

    <v-overlay
      :value="loading"
      absolute
    >
      <v-progress-circular
        class="mx-auto mb-4 d-block"
        indeterminate
        size="32"
      ></v-progress-circular>
      <div class="text-center font-weight-medium white--text">
        Adding Charity
      </div>
    </v-overlay>
  </div>
</template>

<script>
import DialogSmall from '../wrapper/DialogSmall'
import CharityBox from './CharityBox'
import SelectChildCharity from './SelectChildCharity'

export default {
  name: 'CharitiesFeatured',
  components: { DialogSmall, SelectChildCharity, CharityBox },
  data() {
    return {
      sumCharity: null,
      loading: false,
      infoDialog: false,
      selectChildCharityDialog: false,
      charityWithChildren: null,
    }
  },
  async mounted() {
    await this.$store.dispatch('app/load_charities')
  },
  methods: {
    async selectCharity(charity) {
      if (charity.children && charity.children.length) {
        this.charityWithChildren = charity
        this.selectChildCharityDialog = true
        return
      }
      this.loading = true
      await this.$store
        .dispatch('account/save_entity_charity', {
          abn: Number(charity.abn),
          name: charity.name.trim(),
          type: 'charity',
        })
        .then((id) => {
          this.$emit('add', id)
        })

      this.loading = false
    },

    async selectChildCharity(charity) {
      this.loading = true
      await this.$store
        .dispatch('account/save_entity_charity', {
          abn: Number(charity.abn),
          name: charity.name.trim(),
          type: 'charity',
        })
        .then((id) => {
          this.$emit('add', id)
        })

      this.loading = false
    },
  },

  computed: {
    charities() {
      let featured = this.$store.getters['app/charities_list_featured'](
        this.userState
      )
      return featured ? featured.slice(0, 6) : null
    },
    userState() {
      return (
        this.$store.getters['account/user_address'].value.state || 'default'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.w-title > :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.charity-wrapper {
  position: relative;
  border: 2px solid #d6dce7;
  border-radius: 6px;
  margin: 0;
  justify-content: center !important;
  .header {
    min-height: 60px;
  }
}
</style>
