<template>
  <div>
    <div v-if="local">
      <h2 class="mb-2">Your pet's name</h2>
      <v-text-field
        v-model="local.name"
        v-bind="textField"
        @input="showErrorsSwitch(true, 'name')"
        @blur="showErrorsSwitch(true, 'name')"
        :error-messages="
          showErrors.name && errorMessages.name ? errorMessages.name : null
        "
      />

      <h2 class="mb-4">What type of animal is this pet?</h2>
      <v-text-field
        v-model="local.petType"
        v-bind="textField"
        placeholder="eg. dog, cat, iguana, etc"
        :error-messages="
          showErrors.petType && errorMessages.petType
            ? errorMessages.petType
            : null
        "
        @input="showErrorsSwitch(true, 'petType')"
        @blur="showErrorsSwitch(true, 'petType')"
      />

      <div class="mb-2">
        <h2 class="mb-4">Who should be this pet's guardian?</h2>
        <p>Select the person you would like to leave this pet to.</p>
        <transition
          name="component-fade"
          mode="out-in"
        >
          <item-person
            v-if="localFormatted.guardian"
            :key="localFormatted.guardian"
            :id="localFormatted.guardian"
            class="mb-4"
          />
        </transition>

        <btn-add-large
          @click="openDrawer = true"
          :label="buttonLabel"
          :icon="buttonIcon"
        />
        <v-input
          :error-messages="
            showErrors.guardian && errorMessages.guardian
              ? errorMessages.guardian
              : null
          "
        />
      </div>

      <h2 class="mb-4">
        Would you like to leave a gift for maintenance of this pet?
      </h2>
      <p>
        If you would like to leave a sum of money to the person nominated to
        care for your pet, on the condition that that person does indeed care
        for your pet, enter the dollar amount here.
      </p>
      <v-text-field
        v-model="local.maintenance"
        v-bind="textField"
        v-money="moneyFormat"
        :error-messages="
          showErrors.maintenance && errorMessages.maintenance
            ? errorMessages.maintenance
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Save</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawer"
      title="Add Guardian"
    >
      <drawer-person-select
        v-if="openDrawer"
        @select="addGuardian"
        :exclude="[localFormatted.guardian]"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { VMoney } from 'v-money'

import BtnAddLarge from '../../../components/ui/BtnAddLarge'
import Drawer from '../../../components/wrapper/Drawer'
import DrawerPersonSelect from '../../../components/wrapper/DrawerPersonSelect'
import ItemPerson from '../../../components/wrapper/ItemPerson'

export default {
  name: 'WillFormPet',
  components: { BtnAddLarge, Drawer, ItemPerson, DrawerPersonSelect },
  props: {
    closeLabel: {
      default: 'close',
    },
    id: {
      default: null,
    },
  },
  directives: { money: VMoney },
  data: () => ({
    local: null,
    showErrors: {
      name: false,
      petType: false,
      maintenance: true,
      guardian: false,
    },
    processing: false,
    openDrawer: false,
    moneyFormat: {
      decimal: '.',
      thousands: ',',
      precision: 0,
      prefix: '$',
      masked: false,
    },
  }),
  mounted() {
    this.local = cloneDeep(this.entity_pet)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch('account/save_entity_pet', {
        id: this.id,
        pet: this.localFormatted,
      })
      await this.$store.dispatch('account/update_will_progress', ['pets'])
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addGuardian(id) {
      this.local.guardian = id
      this.openDrawer = false
    },
  },
  computed: {
    entity_pet() {
      return this.$store.getters['account/entity_pet'](this.id)
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!/^[A-Za-z0-9\- ‘’'`]+$/.test(this.localFormatted.name)) {
        msgs.name = 'Invalid character'
      }
      if (!/^[A-Za-z\- ‘’'`]*$/.test(this.localFormatted.petType)) {
        msgs.petType = 'Invalid character'
      }

      if (!this.localFormatted.name.length) {
        msgs.name = 'Required'
      }
      if (!this.localFormatted.petType.length) {
        msgs.petType = 'Required'
      }
      if (!this.localFormatted.guardian) {
        msgs.guardian = 'Required'
      }

      if (
        Number(this.localFormatted.maintenance.replace(/[^0-9.-]+/g, '')) >
        1000000
      ) {
        msgs.maintenance = 'Maximum of $1,000,000 exceeded'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.entity_pet)
    },
    localFormatted() {
      return {
        ...this.entity_pet,
        name: this.local?.name.trim().replace(/\s\s+/g, ' ') || '',
        petType: this.local?.petType.trim().replace(/\s\s+/g, ' ') || '',
        maintenance: this.local?.maintenance || '$0',
        guardian: this.local?.guardian || null,
      }
    },
    buttonLabel() {
      return this.localFormatted.guardian ? 'Change Person' : 'Add Person'
    },
    buttonIcon() {
      return this.localFormatted.guardian ? 'mdi-sync' : 'mdi-plus'
    },
  },
}
</script>

<style lang="scss"></style>
