<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4 line-height-md">
          How would you like your body to be treated after you have passed?
        </h2>
        <p>
          Although your funeral wishes will be made known to your Executor, they
          are not binding.
        </p>
        <item-select
          v-for="item in options"
          :key="item.key"
          :value="item.key === localFormatted.remains"
          :title="item.label"
          class="mb-2"
          @select="local.remains = item.key"
        >
          <v-expand-transition v-if="askPrepaidEbook">
            <div
              v-show="
                item.key === 'cremated' && localFormatted.remains === 'cremated'
              "
              class="pa-4"
            >
              <v-card
                color="#f2f2f2"
                flat
              >
                <v-list-item>
                  <v-list-item-action class="mr-4">
                    <v-img
                      src="/images/ebook-cover.svg"
                      width="80px"
                      height="110px"
                      style="
                        border: 1px solid rgba(0, 0, 0, 0.05);
                        border-radius: 4px;
                      "
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-action>
                  <v-list-item-content>
                    <div class="font-weight-bold mb-4">
                      {{ prepaid_ebook.question }}
                    </div>
                    <div>
                      <v-list-item
                        link
                        @click="confirmEbook = !confirmEbook"
                      >
                        <v-list-item-action class="mr-2">
                          <v-icon
                            color="black"
                            v-if="!confirmEbook"
                            >mdi-checkbox-blank-outline</v-icon
                          >
                          <v-icon
                            v-else
                            color="primary"
                            >mdi-checkbox-marked</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content> Yes </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </v-expand-transition>
        </item-select>
        <v-input
          :error-messages="
            showErrors.remains && errorMessages.remains
              ? errorMessages.remains
              : null
          "
        />

        <notification-box
          type="info"
          class="mb-8"
          :flat="true"
        >
          <strong>Bonus:</strong> You can leave more detailed instructions for
          your Funeral in the 'Funeral' section of your Willed account, which
          becomes available once you purchase your Will.
        </notification-box>
      </div>
      <div class="d-flex align-center">
        <v-btn
          text
          @click="close"
          >{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Save</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { mapGetters } from 'vuex'

import ItemSelect from '../../../components/ui/ItemSelect'
import NotificationBox from '../../../components/ui/NotificationBox'

export default {
  name: 'WillFormFuneralWishes',
  components: { NotificationBox, ItemSelect },
  props: {
    closeLabel: {
      default: 'close',
    },
  },
  data: () => ({
    local: null,
    confirmEbook: false,
    showErrors: {
      remains: false,
      message: true,
    },
    processing: false,
    allowFamilyOption: false,
  }),
  mounted() {
    this.local = cloneDeep(this.will_funeral_wishes.value)
    if (this.local.remains === 'family') {
      this.allowFamilyOption = true
    }
  },
  methods: {
    async submit() {
      this.processing = true

      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }

        if (this.localFormatted.remains === 'cremated' && this.confirmEbook) {
          await this.$store.dispatch('account/save_user_data_deep', {
            meta: {
              sendPrepaidEbook: true,
            },
          })
        }

        setTimeout(() => {
          this.close()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch(
        'account/save_will_funeral_wishes',
        this.localFormatted
      )
      await this.$store.dispatch('account/update_will_progress', ['wishes'])
    },
    close() {
      this.$emit('close')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    validRemainSelection(key) {
      const keys = this.options.map((option) => {
        return option.key
      })

      return keys.includes(key)
    },
  },
  computed: {
    ...mapGetters('account', [
      'will_funeral_wishes',
      'user_meta_data',
      'user_address',
    ]),
    ...mapGetters('app', ['prepaid_ebook']),
    askPrepaidEbook() {
      if (this.user_meta_data.sendPrepaidEbook) return false
      if (!this.prepaid_ebook.isActive) return false
      if (!this.prepaid_ebook.question || !this.prepaid_ebook.question.length)
        return false

      let states = this.prepaid_ebook.states || []
      if (!states.includes(this.user_address.value.state)) return false

      return true
    },
    options() {
      return this.$store.getters['account/list_group']('remains').filter(
        (o) => {
          return o.key !== 'family' || this.allowFamilyOption
        }
      )
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        remains: null,
        message: null,
      }

      if (!this.localFormatted.remains) {
        msgs.remains = 'Required field'
      }

      if (this.localFormatted.message.length > 100000) {
        msgs.message = 'Maximum length of 100,000 characters'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_funeral_wishes.value)
    },
    localFormatted() {
      return {
        remains:
          this.local.remains && this.validRemainSelection(this.local.remains)
            ? this.local.remains
            : null,
        message: this.local.message.trim(),
      }
    },
  },
}
</script>

<style lang="scss"></style>
